import axios from 'axios';
import { AppConfig } from 'config/api_config';
import { Auth } from 'aws-amplify';
import { default as Endpoints } from './apiEndPoints';
import { logout } from 'utils/utils';

const makeRequest = async (method, url, data, baseURL, login = true, excludeFromLoading = false) => {
  url = url.replaceAll('#', '%23');
  url = url.replaceAll('+', '%2B');
  const config = {
    method,
    url,
    baseURL,
    excludeFromLoading,
  };
  if (
    method === Endpoints.methodType.PUT ||
    method === Endpoints.methodType.PATCH ||
    method === Endpoints.methodType.POST ||
    method === Endpoints.methodType.DELETE
  ) {
    config.data = data;
  }
  if (method === Endpoints.methodType.GET) {
    config.params = data;
  }
  config.headers = {
    'Content-Type': 'application/json; charset=utf-8',
  };

  if (login) {
    let token;
    typeof global?.setLoader === 'function' && global?.setLoader(true);
    await Auth.currentSession()
      .then((data) => {
        typeof global?.setLoader === 'function' && global?.setLoader(false);
        token = data.getIdToken().getJwtToken();
      })
      .catch(() => {
        typeof global?.setLoader === 'function' && global?.setLoader(false);
        logout();
      });
    config.headers.Authorization = token;
  }
  return axios.request(config);
};

export const performRequest = async (method, url, data, _timeEntryData, login = true, excludeFromLoading = false) => {
  return makeRequest(method, url, data, AppConfig.baseURL, login, excludeFromLoading);
};

export const performRequestSecond = async (method, url, data, _timeEntryData, login = true, excludeFromLoading = false) => {
  return makeRequest(method, url, data, AppConfig.baseURL2, login, excludeFromLoading);
};

export const performRequestThird = async (method, url, data, _timeEntryData, login = true, excludeFromLoading = false) => {
  return makeRequest(method, url, data, AppConfig.baseURL3, login, excludeFromLoading);
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { message } = error?.response || error;
    if (
      message === 'Refresh Token has expired' ||
      message === 'Invalid Refresh Token' ||
      message === 'Refresh Token has been revoked' ||
      message === 'No current user'
    ) {
      // window.open(`http://${process.env.REACT_APP_DOMAIN}/#/login`,"_self")

      logout();
    }
    return Promise.reject(error);
  }
);

export const axiosInstance = axios;
